.faq-section {
    background-color: #f8f8f8;
    padding: 40px 20px;
    text-align: center;
}

.faq-section .container {
    max-width: 1200px;
    margin: 0 auto;
}

.faq-section .container h2 {
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
}

.faq-columns {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
}

.faq-question.shadow {
    /* box-shadow: 0 -3px 8px rgba(0, 0, 0, 0.1); */
    border-radius: 10px 10px 0px 0px;
}

.faq-question {
    color: #292929;
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    padding: 15px;
    font-size: 18px;
    cursor: pointer;
    /* border-top: 1px solid #ccc; */
    background-color: #ffffff;
    margin-bottom: 0px;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    text-align: left;
    overflow: hidden;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.faq-answer p {
    color: #7f8c8d;
    padding: 10px 10px;
    border-top: 1px solid #e4e4e4;
}

.faq-answer {
    background-color: #ffffff;
    border-radius: 0px 0px 10px 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    /* padding: 10px 0px; */
    /* margin-top: 0px; */
    font-size: 15px;
    color: #353535;
    text-align: left;
    /* margin-bottom: 20px; */
}

.line {
    width: 100%;
    border: 0.4px solid #e9e9e9;
    margin: 10px 0px;
}

.faq-answer.open {
    max-height: 200px;
    /* padding: 10px 0px; */
    text-align: left;
    font-size: 15px;
    color: #353535;
    /* margin-bottom: 20px; */
}

.plus {
    float: right;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .faq-columns {
        display: grid;
        grid-template-columns: auto;
        gap: 20px;
    }

    .faq-column {
        flex: 1 1 100%; /* Stack the columns on smaller screens */
    }
}
