

.app .carousel-overlay {
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    padding: 50px 0;
    /* margin: 17px 0px; */
    backdrop-filter: blur(0px);
}

@media (max-width: 600px) {
    .app .carousel-overlay {
        display: flex;
        justify-content: end;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        padding: 50px 20px;
        margin: 17px 0px;
        backdrop-filter: blur(0px);
    }
}
.app .carousel-overlay .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-self: center;
    justify-content: end;
}

.form-container {
    margin-right: 50px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 350px;
}

input, textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.form-container .mobile-input {
    flex-direction: row;
    display: flex;
    align-items: center;
}
.form-container .mobile-input .country-code.country-code-active {
    border: 1px solid #7a7a7a !important;
    border-right-width: 0px !important;
    border-right-color: transparent !important;
}
.form-container .mobile-input .country-code {
    flex-direction: row;
    display: flex;
    align-items: center;
    border: 1px solid #e5e5e5;
    border-right-width: 0px;
    padding: .600rem .75rem;
    gap: 6px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.form-container .mobile-input .country-code .country-image {
    width: 24px;
    height: 18px;
    border-radius: 3px;
}
/* .form-container .mobile-input .country-code .country {

} */

.button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 10px;
}
  
/* .form-container {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 60%;
    max-width: 500px;
    margin: 2vh auto;
    transition: all 0.3s ease-in-out;
} */

.form-group {
    margin-bottom: 15px;
}

.form-container h3 {
    text-align: center;
    margin-bottom: 10px;
}

label {
    color: #222;
    font-weight: 600;
    margin-bottom: 3px;
    margin-left: 3px;
    display: block;
    font-size: 15px;
    font-family: Poppins, sans-serif;
}

input[type="text"],
input[type="email"],
input[type="number"],
textarea {
    appearance: none !important;
    background-clip: padding-box !important;
    background-color: #fff !important;
    border: 1px solid #e5e5e5 !important;
    border-radius: 4px;
    color: #666 !important;
    display: block !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    padding: .600rem .75rem !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
    width: 100% !important;
    margin-bottom: 0px !important;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus {
    /* background-color: #e3faff !important; */
    box-shadow: 0 0 0 0 rgba(13, 110, 253, .25);
    color: #000 !important;
    outline: 0;
    border: 1px solid #7a7a7a !important;
}

textarea {
    height: 100px;
    resize: vertical;
}

.submit-btn {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    text-transform: uppercase;
}

.submit-btn:hover {
    background-color: #0056b3;
}

.g-recaptcha {
    margin-top: 10px;
}

/* Responsive adjustments for the form */
@media (max-width: 1300px) {
    .form-container {
        width: 50% !important;
    }
}
@media (max-width: 600px) {
    .form-container {
        width: 100% !important; /* Wider on small screens for better readability */
        padding: 15px !important; /* Slightly reduced padding */
        margin-right: 0px !important;
        margin-top: 80px;
        /* box-shadow: 2px 2px 12px 0px #d5d5d5; */
    }

    label, input, textarea, .submit-btn {
        font-size: 14px; /* Slightly smaller font size on small devices */
    }
}
