.cta-section {
    background-color: #ffffff;
    text-align: center;
    padding: 40px 20px;
}

.cta-section .container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
    text-align: left;
}

.cta-section h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

.cta-content {
    display: flex;
    flex-direction: column-reverse; /* Image below text on small screens */
    align-items: center;
}

.cta-image {
    max-width: 50%; /* Adjust image size accordingly */
    height: auto;
    margin-bottom: 20px; /* Space between image and text */
}

.text-content {
    /* background-color: #ffffff; */
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.enquire-button {
    background-color: #e53935;
    color: white;
    border: none;
    padding: 10px 30px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px; /* Space between text and button */
    transition: background-color 0.3s;
}

.enquire-button:hover {
    background-color: #b71c1c;
}

.contact-info {
    margin-top: 10px;
    color: #333;
    font-size: 16px;
}

/* Responsive Adjustments */
@media (min-width: 768px) {
    .cta-content {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 45px;
    }

    .text-content {
        margin-left: 20px; /* Space between image and text content */
    }

    .cta-section h2 {
        font-size: 28px; /* Larger heading for larger screens */
    }
}

@media (max-width: 768px) {
    .cta-image {
        max-width: 100%; /* Full width image on smaller screens */
    }
    .cta-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}
