.carousel {
    width: 100%;
    height: 70vh;
    overflow: hidden;
    display: flex;
    position: relative;
}

.slide {
    min-width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slide img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
}

/* Responsive adjustments for carousel */
@media (max-width: 600px) {
    .carousel, .slide {
        height: 90vh;  /* Reducing the height on smaller screens */
        width: 100%;
    }

    .slide img {
        height: 90vh;
        width: 100%;
    }
}
