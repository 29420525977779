.why-choose-us {
    background-color: #f9f9f9;
    padding: 40px 20px;
    text-align: center;
}

.why-choose-us .container {
    max-width: 1200px;
    margin: 0 auto;
}

.why-choose-us h2 {
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
}

.benefits {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.benefit {
    width: calc(25% - 20px);
    background: #ffffff;
    padding: 20px;
    /* box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.benefit-icon img {
    margin-bottom: 15px;
    width: 60px;
    height: auto;
}

.benefit:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgb(169 169 169 / 15%)
}

.benefit h3 {
    /* font-size: 18px; */
    color: #292929;
    margin: 10px 0;
}

.benefit p {
    /* font-size: 16px; */
    color: #7f8c8d;
}

@media (max-width: 768px) {
    .benefit {
        width: calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .benefit {
        width: 100%;
    }
}
