.header {
    background-color: #000;
    padding: 0.5rem 2rem;
    color: white;
    /* position: relative; */
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000000;
}

.header .container {
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.header-left {
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
    align-items: center;
}

.menu-icon {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
    /* order: 2; */
}

.logo {
    height: 40px;
    order: 1;
    margin-left: 30px;
}

.header-right {
    display: flex;
    align-items: center;
}

.header-button {
    background-color: transparent;
    border: 0;
    color: white;
    padding: 8px 14px;
    border-radius: 10px;
    margin-left: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 0.9rem;
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    gap: 10px;
}
.header-button.active {
    background-color: white;
    color: black;
    border-radius: 10px;
}

.header-button:hover {
    background-color: white;
    color: black;
    border-radius: 10px;
}

.header-button:hover svg path {
    fill: #000;
}

.close-button {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.overlay {
    width: 100%;
    height: 100vh;
    /* background-color: rgba(31,31,31,.87);
    filter: blur(10px); */
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 60px;
    z-index: 10000000;
}

.sidebar {
    position: fixed;
    top: 0;
    margin-top: 60px;
    left: 0;
    width: 20vw;
    height: 100vh;
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 30px; */
    padding-top: 50px;
    transform: translateX(-100%);
    animation: slideIn 0.3s forwards;
    z-index: 10000000;
}

@keyframes slideIn {
    from { transform: translateX(-100%); }
    to { transform: translateX(0); }
}

@media (max-width: 608px) {
    .header-left {
        width: 100%;
    }
    .header-right {
        display: none !important;
    }
    .logo {
        height: 40px;
        order: 1;
        margin-left: 0px;
    }
    .menu-icon {
        margin-left: auto; /* Ensures the icon is on the right */
        order: 2;  /* Moves the menu icon to the right */
    }
    .header {
        padding: 0.5rem 1rem;
    }
    .overlay {
        margin-top: 0px;
    }
    .sidebar {
        width: 100%;
        margin-top: 0px;
    }
    .close-button {
        display: block;
        background: none;
        border: none;
        color: white;
        font-size: 2rem;
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
    }
}
