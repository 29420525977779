/* Reset CSS */
/* @font-face {
  font-family: "icomoon";
  font-display: swap;
  src: local("icomoon"),
    url("//c.housingcdn.com/demand/s/common/assets/style.b49dbc83.woff2")
      format("woff2"),
    url("//c.housingcdn.com/demand/s/common/assets/style.2cafc76c.woff")
      format("woff"),
    url("//c.housingcdn.com/demand/s/common/assets/style.6b575c03.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
}

a {
  list-style: none;
  text-decoration: none;
  /* font-family: sans-serif; */
  font-family: Poppins, sans-serif;
}

html {
  scroll-behavior: smooth;
  scroll-padding: 90px;
}

body {
  font-family: Poppins, sans-serif;
}

.active {
  color: red; /* Change as needed */
  font-weight: bold;
}

.app {
  position: relative;
  height: 70vh;
  margin-top: 60px;
}

.carousel img {
  width: 100%;
  height: 70vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 600px) {
  .app {
    position: relative;
    height: 90vh;
    margin-top: 60px;
  }
  .carousel img {
    height: 90vh;
  }
  .carousel img {
    height: 90vh;
  }
}

.content {
  margin-left: 50px;
}

.main {
  /* max-width: 1200px; */
  margin: 0 auto;
  display: flex;
  border-bottom: 1px solid #dedede;
  height: 100%;
}

.policy-tab-view {
  width: 20%;
  /* height: 100%; */
  padding: 20px 0px;
  flex-direction: column;
  display: flex;
  gap: 10px;
  border-right: 1px solid;
  border-color: #dedede;
  padding: 10px 10px;
  position: sticky;
  top: 70px;
}

.policy-tab-view a, .policy-tab-view-modal a {
  color: #606770;
  font-size: 17px;
  line-height: 28px;
  font-weight: 500;
  font-family: Rubik, Helvetica, sans-serif;
  padding: 8px 11px;
}

.policy-tab-view a.active, .policy-tab-view-modal a.active {
  color: #df293a;
  font-size: 17px;
  line-height: 28px;
  font-weight: 500;
  font-family: Rubik, Helvetica, sans-serif;
  background-color: #f2f2f2;
  padding: 8px 11px;
  border-radius: 10px;
}

.policy-tab-view a:hover, .policy-tab-view-modal a:hover {
  color: #df293a;
  font-size: 17px;
  line-height: 28px;
  font-weight: 500;
  font-family: Rubik, Helvetica, sans-serif;
  background-color: #f2f2f2;
  padding: 8px 11px;
  border-radius: 10px;
}

.policy-tab-content {
  width: 60%;
  padding: 50px 50px 20px 60px;
}

.main .policy-tab-content p {
  color: #1d1d1b;
  font-size: 50px;
  font-weight: 700;
  line-height: 2.8rem;
  margin: 20px 0;
  /* text-align: center; */
  text-transform: capitalize;
}

.main .policy-tab-content span {
  color: #253858;
  display: flex;
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 30px;
}

.main .policy-tab-content section h5 {
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 23px;
}

.main .policy-tab-content section ul {
  padding-left: 2rem;
}

.main .policy-tab-content section ul li {
  color: rgb(81 95 119);
  /* display: flex; */
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.policy-tab-content-topic {
  width: 20%;
  /* height: 100%; */
  /* right: 0; */
  margin-bottom: 0px;
  border-left: 1px solid;
  border-color: #dedede;
}

.table-content {
  padding: 40px 10px 40px 10px;
  position: sticky;
  top: 62px;
}

.table-content h2 {
  color: #333;
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
}

.table-content ol {
  padding-left: 2rem;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.table-content ol li, .table-content ol li a {
  color: rgb(81 95 119);
  font-size: 16px;
  font-weight: 400;
}

.policyChange {
  display: none;
  background-color: #dedede;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 9px 21px;
  border-radius: 10px;
  font-size: 19px;
}

.policy-tab-view-modal {
  position: fixed;
  bottom: 77px;
  right: 23px;
  background-color: #f4f4f4;
  padding: 10px 20px;
  border-radius: 10px;
}

/* Media Queries for Mobile Devices */
@media screen and (max-width: 768px) {
  .main {
    flex-direction: column;
  }

  .policy-tab-view {
    position: fixed;
    top: 50px;
    left: 0;
    height: 100vh;
    width: 100% !important;
    background: white;
    z-index: 200;
    transform: translateX(-100%);
  }

  .menu-icon {
    display: block; /* Show menu icon on smaller screens */
  }

  .policy-tab-content {
    width: 100% !important;
    /* padding: 10px; */
    padding: 30px 30px 30px 30px !important;
  }

  .policy-tab-content p {
    font-size: 24px;
  }

  .policy-tab-content-topic {
    display: none;
  }

}

/* Media Queries for Tablet Devices */
@media screen and (max-width: 1024px) {
  .policy-tab-content {
    width: 100% !important;
  }

  .policy-tab-view {
    display: none !important;
  }

  .policyChange {
    display: block !important;
  }

  .policy-tab-content-topic {
    display: none;
  }

  .policy-tab-view {
    width: 25%;
  }
}